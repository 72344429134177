import type { FC } from "react";
import { Wrapper } from "@ui/blocks/TickIcon/elements/Wrapper";
import { Svg } from "@ui/blocks/TickIcon/elements/Svg";

export const TickIcon: FC = () => {
  return (
    <Wrapper>
      <Svg fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.6 1c.2.3.3.7 0 1l-5 5.3c-.6.7-1.5.7-2.2.1l-2-1.9a.6.6 0 0 1 .9-.9l2 1.9c.1.1.3.1.4 0l5-5.4c.2-.3.6-.3.9 0Z"
          fill="currentColor"
        />
      </Svg>
    </Wrapper>
  );
};
