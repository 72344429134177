import { FC } from "react";
import { FontSize } from "@themeTypes/FontSizeType";
import { XPAImageObjectType } from "@/shared/types/XPAImageObjectType";
import { CardImage } from "@ui/elements/CardImage";
import { ImageWithFallBack } from "@ui/blocks/ImageWithFallBack";
import { CardWrapper } from "@ui/elements/CardWrapper";
import { Link } from "react-router-dom";
import { CardContentTitle } from "@ui/elements/CardContentTitle";
import { CardPartnerNameText } from "@ui/elements/CardPartnerNameText";
import { CardPartnerNameWrapper } from "@ui/elements/CardPartnerNameWrapper";
import { isNullish } from "@/shared/helpers/isNullish";
import { useAppSelector } from "@/shared/hooks/appHooks";
import { selectIsDarkTheme } from "@features/pageLayout/store/pageLayoutSlice";

import playIcon from "/assets/icons/play_icon.svg";
import playIconDark from "/assets/icons/play_icon_dark.svg";
import { ThemeColor } from "@themeTypes/ThemeColorType";
import { DurationWrapper } from "@ui/blocks/VideoCard/elements/DurationWrapper";
import { TitleText } from "@ui/elements/TitleText";
import { PartnerSectionWrapper } from "@ui/blocks/LiveMatchCard/elements/PartnerSectionWrapper";
import { BodyText } from "@ui/elements/BodyText";

export interface VideoCardProps {
  url: string;
  imageObject: XPAImageObjectType;
  title: string;
  providerName?: string;
  publishTime?: string;
  providerLogo?: XPAImageObjectType;
  duration?: string;
  onClick?: () => void;
}

export const VideoCard: FC<VideoCardProps> = ({
  duration,
  url,
  imageObject,
  title,
  providerLogo,
  providerName,
  publishTime,
  onClick,
}) => {
  const isDarkMode = useAppSelector(selectIsDarkTheme);
  return (
    <CardWrapper
      as={Link}
      to={url}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      <CardImage>
        {!isNullish(duration) && (
          <DurationWrapper>
            <ImageWithFallBack
              width={10}
              height={10}
              src={playIcon}
              darkModeSrc={playIconDark}
              isDarkMode={isDarkMode}
            />
            <TitleText
              style={{ marginLeft: "0.2rem" }}
              color={ThemeColor.Background}
              size={FontSize.Title_8}
            >
              {duration}
            </TitleText>
          </DurationWrapper>
        )}
        <ImageWithFallBack
          src={imageObject.path}
          alt={imageObject.alt}
          style={{
            objectPosition: "center",
            objectFit: "cover",
          }}
          width={430}
        />
      </CardImage>
      <CardContentTitle size={FontSize.Title_7}>{title}</CardContentTitle>
      <PartnerSectionWrapper>
        <CardPartnerNameWrapper>
          <ImageWithFallBack
            width={20}
            height={20}
            src={providerLogo?.path}
            alt={providerLogo?.alt}
          />
          <CardPartnerNameText size={FontSize.BodyText_8}>
            {providerName}
          </CardPartnerNameText>
        </CardPartnerNameWrapper>
        <BodyText size={FontSize.BodyText_8}>{publishTime}</BodyText>
      </PartnerSectionWrapper>
    </CardWrapper>
  );
};
