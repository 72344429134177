import styled from "styled-components";
import { WithThemeType } from "@themeTypes/WithThemeType";
import { GlobalStylesConstants } from "@ui/elements/GlobalStyles/GlobalStylesConstants";

export const FilterList = styled.ul<WithThemeType>`
  display: grid;
  overflow: scroll;
  max-height: 18rem;
  position: relative;
  &::after {
    content: "";
    position: sticky;
    height: calc(${GlobalStylesConstants.minSizeInteractiveElement} / 2);
    bottom: 0;
    width: 100%;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      ${({ theme }) => theme.colors.elevatedBackground} 100%
    );
  }
`;
