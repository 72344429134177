import { useParams } from "react-router";
import { useGetMatchDetailQuery } from "@features/matchDetail/services/matchDetailApi";
import { FC, useMemo, useState } from "react";
import { VideoPlayerRelatedVideos } from "@ui/blocks/VideoPlayerRelatedVideos";
import { useTranslation } from "react-i18next";
import { useVideoDetailSettings } from "@/shared/hooks/useVideoDetailSettings";
import { isNullish } from "@/shared/helpers/isNullish";
import { ScreenName } from "@features/tracking/screen-names";
import { usePageTracking } from "@features/tracking/hooks/usePageTracking";
import { useScreenName } from "@features/tracking/hooks/useScreenName";
import { TwoColumnGridLayout } from "@ui/elements/TwoColumnGridLayout";
import { VideoDetailPageWrapper } from "@ui/elements/VideoDetailPageWrapper";
import { BurgerMenu } from "@ui/blocks/BurgerMenu";
import { getMenuAssets } from "@features/pageLayout/helpers/getMenuAssets";
import { WidgetsSidebar } from "@ui/blocks/WidgetsSidebar";
import { MatchInfoWidget } from "@ui/blocks/MatchInfoWidget";
import { utcToLocaleDate } from "@/shared/helpers/utcToLocaleDate";
import { sendVideoPlayedEvent } from "@features/tracking/services/sendVideoPlayedEvent";
import { VideoJSPlayer } from "@ui/blocks/VideoPlayer/types/VideoJSPlayer";
import { useFullScreen } from "@/shared/hooks/useFullScreen";
import { LogoLoadingSpinner } from "@ui/blocks/LoadingIcon/LoadinIcon";
import { LogoSpinnerCentered } from "@ui/blocks/LoadingIcon/LogoSpinnerCentered";

export const MatchDetailPage: FC = () => {
  const { id } = useParams();

  const { i18n } = useTranslation();
  const { t } = i18n;
  const { isWidgetsVisible, isDarkTheme, onToggleWidgetsVisible } =
    useVideoDetailSettings();
  const fullScreenRef = useFullScreen();

  useScreenName(ScreenName.MatchDetail);
  usePageTracking();

  const { data } = useGetMatchDetailQuery({
    locale: i18n.resolvedLanguage ?? "en",
    matchId: id ?? "1",
  });

  const [isAdBlockerActive, setIsAdBlockerActive] = useState(false);

  // ToDo: Add handle blocker logic after we get the strategy for adblocker UX
  const handleAdBlocker = () => {
    setIsAdBlockerActive(true);
  };

  const venue = useMemo(() => {
    if (isNullish(data)) {
      return "";
    }
    return (
      data.matchInfo?.entries.find(
        (entry) => entry.title.toLowerCase().trim() === "stadium",
      )?.subtitle ?? ""
    );
  }, [data]);

  const VideoSection = useMemo(() => {
    if (isNullish(data)) {
      return null;
    }
    const { videoPlayer, liveMatch, relatedVideos, matchScore, ottBanner } =
      data;
    const videoDataToRender =
      videoPlayer ?? liveMatch?.videojsPlayerConfig ?? null;

    const handleVideoPlayedEvent = (player: VideoJSPlayer) => {
      if (isNullish(videoDataToRender)) {
        return;
      }

      sendVideoPlayedEvent({
        duration: player.currentTime() ?? 0,
        fullScreenRef: fullScreenRef,
        player,
        wasFinished: true,
        options: {
          config: videoDataToRender.config,
          playlist: videoDataToRender.playlist,
        },
      });
    };

    return (
      <VideoPlayerRelatedVideos
        matchScoreData={matchScore}
        relatedVideos={{
          sectionTitle: t("RELATED_VIDEOS"),
          relatedVideosCards: relatedVideos?.items,
        }}
        videoPlayer={{
          config: videoDataToRender?.config,
          playlist: videoDataToRender?.playlist ?? [],
          onAdBlockerActive: handleAdBlocker,
          shouldShowAdBlocker: isAdBlockerActive,
          onVideoPlayedEvent: handleVideoPlayedEvent,
        }}
        videoDescription={{
          ...liveMatch,
          partnerImage: {
            src: liveMatch?.partnerImage?.path,
            alt: liveMatch?.partnerImage?.alt,
          },
        }}
        ottBanner={
          !isNullish(ottBanner)
            ? {
                message: ottBanner.message,
                link: {
                  url: ottBanner.link?.urlPath,
                  name: ottBanner.link?.name,
                },
                broughtBy: ottBanner.broughtBy,
                logo: {
                  src: ottBanner.logo?.path,
                  alt: ottBanner.logo?.alt,
                },
              }
            : undefined
        }
      />
    );
  }, [data, fullScreenRef, isAdBlockerActive, t]);

  // ToDo: Handle page loader here
  if (isNullish(data)) {
    return (
      <LogoSpinnerCentered data-testid={"loader"}>
        <LogoLoadingSpinner />;
      </LogoSpinnerCentered>
    );
  }
  const { videoPlayer, liveMatch, matchInfo, matchScore } = data;
  const videoDataToRender =
    videoPlayer ?? liveMatch?.videojsPlayerConfig ?? null;

  return (
    <VideoDetailPageWrapper>
      <TwoColumnGridLayout wide={!isNullish(videoDataToRender)}>
        {!isNullish(videoDataToRender) && (
          <BurgerMenu
            onBurgerClick={() => onToggleWidgetsVisible(true)}
            iconSrc={getMenuAssets(isDarkTheme).widgetsSidebarBurger}
            iconAlt="widgets-sidebar"
            absolutePosition={{
              top: "1.25rem",
              right: "1.25rem",
              bottom: "auto",
              left: "auto",
            }}
          />
        )}
        {VideoSection}
        <WidgetsSidebar
          hasMatch={!isNullish(videoDataToRender)}
          shouldShowSidebar={isWidgetsVisible}
          onOutsideClick={() => onToggleWidgetsVisible(false)}
        >
          {!isNullish(matchInfo) && !isNullish(matchScore) && (
            <MatchInfoWidget
              awayTeam={matchScore.awayTeam}
              competition={matchScore.competition}
              homeTeam={matchScore.homeTeam}
              isDarkMode={isDarkTheme}
              kickoffDate={utcToLocaleDate(matchScore.kickoff.utcTimestamp)}
              kickoffTime={matchScore.kickoff.time}
              timePeriod={matchScore.timePeriod ?? ""}
              venue={venue}
            />
          )}
        </WidgetsSidebar>
      </TwoColumnGridLayout>
    </VideoDetailPageWrapper>
  );
};
