import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useLazyGetLiveCompetitionsListQuery } from "@features/liveMatches/services/liveMatchesApi";
import { useLocale } from "@/shared/hooks/useLocale";
import { discoveryApi } from "@features/discovery/services/discoveryApi";
import { useAppDispatch, useAppSelector } from "@/shared/hooks/appHooks";
import { FontSize } from "@themeTypes/FontSizeType";
import { FiltersWrapper } from "@ui/elements/FiltersWrapper";
import { LiveCompetitionsFilter } from "@ui/blocks/LiveCompetitionsFilter";
import {
  selectFilteredCompetition,
  selectFilteredPrice,
  setCurrentPage,
  setFilteredCompetition,
  setFilteredPrice,
} from "@features/liveMatches/store/liveMatchesSlice";
import { selectIsDarkTheme } from "@features/pageLayout/store/pageLayoutSlice";
import { useOutsideClick } from "@/shared/hooks/useOutsideClick";
import { PriceFilter } from "@ui/blocks/PriceFilter";
import { TitleText } from "@ui/elements/TitleText";
import { useSearchParams } from "react-router-dom";
import { EventName } from "@/modules/tracking/constants/EventName";
import { FilterType } from "@/modules/tracking/constants/FilteType";
import { trackingService } from "@features/tracking/services/trackingService";
import { LiveCompetitionsListDataType } from "@/shared/types/LiveCompetitionsListDataType";

export interface FiltersContainerProps {
  filtersDrawerOpened: boolean;
  onFiltersDrawerOutsideClick: () => void;
}

export const FiltersContainer: FC<FiltersContainerProps> = ({
  filtersDrawerOpened,
  onFiltersDrawerOutsideClick,
}) => {
  const { t, locale } = useLocale();
  const dispatch = useAppDispatch();
  const isDarkTheme = useAppSelector(selectIsDarkTheme);
  const filteredPrice = useAppSelector(selectFilteredPrice);
  const filteredCompetition = useAppSelector(selectFilteredCompetition);

  const [competitionsList, setCompetitionsList] = useState<
    Array<LiveCompetitionsListDataType>
  >([]);

  // We need to handle the call manually, due to unnecessary api calls when
  // selecting competitions
  const [getCompetitionsList] = useLazyGetLiveCompetitionsListQuery();

  const [, setSearchParams] = useSearchParams();

  const ref = useOutsideClick(onFiltersDrawerOutsideClick);

  useEffect(() => {
    getCompetitionsList({ locale })
      .then((res) => {
        setCompetitionsList(res.data ?? []);
      })
      .catch((err) => {
        // fails in silence and sadness
      });
  }, [getCompetitionsList, locale]);

  const onCompetitionItemClick = useCallback(
    (itemID: string): void => {
      void trackingService.sendEvent({
        name: EventName.FiltersApplied,
        properties: {
          filter_type: FilterType.competition,
        },
      });

      setSearchParams(itemID === "" ? "" : { competitionID: itemID });

      dispatch(discoveryApi.util.resetApiState());
      dispatch(setCurrentPage(0));
      dispatch(setFilteredCompetition(itemID));
    },

    // changing searchParams should not re-render competitionListItems
    // therefore it's omitted from dependencies on purpose
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch],
  );

  const onPriceItemClick = (itemPrice: string): void => {
    if (itemPrice === filteredPrice) {
      return;
    }

    void trackingService.sendEvent({
      name: EventName.FiltersApplied,
      properties: {
        filter_type: FilterType.price,
        price: itemPrice,
      },
    });

    dispatch(discoveryApi.util.resetApiState());
    dispatch(setCurrentPage(0));
    dispatch(setFilteredPrice(itemPrice));
  };

  const priceFilterItems = useMemo(
    () => [
      { text: t("FILTERS_ALL"), id: "" },
      { text: t("FILTERS_SUBSCRIPTION"), id: "fta" },
      { text: t("FILTERS_PAY_PER_VIEW"), id: "ppv" },
    ],
    [t],
  );

  return (
    <FiltersWrapper
      ref={ref}
      opened={filtersDrawerOpened}
      data-testid="filters-wrapper"
    >
      <TitleText size={FontSize.Title_5} style={{ margin: "1.2rem 0" }}>
        {t("FILTERS")}
      </TitleText>
      <LiveCompetitionsFilter
        title={t("FILTER_BY_COMPETITION")}
        items={competitionsList}
        onItemClicked={onCompetitionItemClick}
        isDarkTheme={isDarkTheme}
        selectedItem={filteredCompetition}
      />
      <PriceFilter
        title={t("FILTERS_PRICE")}
        isDarkTheme={isDarkTheme}
        items={priceFilterItems}
        onItemClicked={onPriceItemClick}
        selectedItem={filteredPrice}
      />
    </FiltersWrapper>
  );
};
