import type { ReactElement } from "react";
import { useEffect, useRef, useState } from "react";
import videojs from "video.js";
import "videojs-ima";
import videoJSPlaylist from "videojs-playlist";
import "video.js/dist/video-js.css";
import "videojs-ima/dist/videojs.ima.css";
import eme from "videojs-contrib-eme";

import { VideoJSPlayer } from "@ui/blocks/VideoPlayer/types/VideoJSPlayer";
import { VideoJSPlayerProps } from "@ui/blocks/VideoPlayer/types/VideoJSPlayerProps";
import {
  checkForAdBlocker,
  importAdPlugin,
} from "@ui/blocks/VideoPlayer/services/adPluginService";
import { initVideoPlayer } from "@ui/blocks/VideoPlayer/services/initVideoPlayer";
import { PlayerLoader } from "@ui/blocks/PlayerLoader";
import { PlayerDOMElement } from "@ui/blocks/VideoPlayer/elements/PlayerDOMElement";
import { attachPlayerEvents } from "@ui/blocks/VideoPlayer/helpers/attachPlayerEvents";
import { VideojsPlaylistItem } from "@ui/blocks/VideoPlayer/types/VideoPlaylistItem";
import { AdBlockerBanner } from "@ui/blocks/AdBlockerBanner";
import { create, func } from "superstruct";
import { isNullish } from "@/shared/helpers/isNullish";
import { initComscoreAnalytics } from "@ui/blocks/VideoPlayer/services/initComscoreAnalytics";
import { VideoPlayerEvent } from "@ui/blocks/VideoPlayer/types/VideoPlayerEventType";
import Skeleton from "react-loading-skeleton";

const VideoPlayer = ({
  config,
  playlist,
  shouldShowAdBlocker,
  onAdBlockerActive,
  CustomLoader,
  adBlockerBaitScriptUrl,
  onVideoPlayedEvent,
  events,
}: VideoJSPlayerProps): ReactElement => {
  const placeholderRef = useRef<HTMLDivElement | null>(null);
  const playerInstanceRef = useRef<VideoJSPlayer | null>(null);
  const isVideoPlayedEventSentRef = useRef(false);
  const playlistRef = useRef<Array<VideojsPlaylistItem> | null>(null);
  const [loading, setLoading] = useState(false);

  // only enable video_played event sending on video play,
  // but wait until other conditions are met to actually send
  const shouldSendVideoPlayedEventRef = useRef(false);

  useEffect(() => {
    // prevent fetching bait script if ad-blocker already active
    if (shouldShowAdBlocker || isNullish(config)) {
      return;
    }
    setLoading(true);

    checkForAdBlocker(adBlockerBaitScriptUrl)
      .catch((e: Error) => {
        onAdBlockerActive(e);
        throw new Error("Ad blocker active");
      })
      .then(importAdPlugin)
      .then((plugin) => {
        // check if plugin already registered
        const adsPlugin = videojs.getPlugin("ads");
        const emePlugin = videojs.getPlugin("eme");
        const playlistPlugin = videojs.getPlugin("playlist");

        if (typeof adsPlugin !== "function") {
          videojs.registerPlugin("ads", plugin);
        }

        // DRM plugin manual init
        if (typeof emePlugin !== "function") {
          const emePlg = create(eme, func(), "Can not create eme plugin");
          videojs.registerPlugin("eme", emePlg);
        }

        // Playlist plugin manual init
        if (typeof playlistPlugin !== "function") {
          const playlistPlg = create(
            videoJSPlaylist,
            func(),
            "Can not create playlist plugin",
          );
          videojs.registerPlugin("playlist", playlistPlg);
        }

        if (placeholderRef.current && !playerInstanceRef.current) {
          // Copy playlist as object could be coming from immutable
          // playlist plugin is trying to extend it
          playlistRef.current = structuredClone(playlist);

          playerInstanceRef.current = initVideoPlayer(
            placeholderRef.current,
            config,
            playlistRef.current,
          );

          if (playerInstanceRef.current !== null) {
            if (
              !isNullish(playlist[0]) &&
              !isNullish(playlist[0].comscoreMetadata)
            ) {
              initComscoreAnalytics({
                player: playerInstanceRef.current,
                comscoreMetadata: playlist[0].comscoreMetadata,
              });
            }

            playerInstanceRef.current.on(VideoPlayerEvent.Play, () => {
              shouldSendVideoPlayedEventRef.current = true;
            });

            playerInstanceRef.current.on(VideoPlayerEvent.Ended, () => {
              if (
                !isNullish(onVideoPlayedEvent) &&
                !isNullish(playerInstanceRef.current)
              ) {
                onVideoPlayedEvent(playerInstanceRef.current);
              }

              isVideoPlayedEventSentRef.current = true;
            });

            attachPlayerEvents(playerInstanceRef.current, events);
          }
        }
      })
      .catch((e: Error) => {
        // Player init error
        // Fails in silence and sadness
      })
      .finally(() => {
        setLoading(false);
      });

    return () => {
      if (document.pictureInPictureElement) {
        return;
      }

      if (
        shouldSendVideoPlayedEventRef.current &&
        !isVideoPlayedEventSentRef.current &&
        !isNullish(onVideoPlayedEvent) &&
        !isNullish(playerInstanceRef.current)
      ) {
        onVideoPlayedEvent(playerInstanceRef.current);
      }

      playerInstanceRef.current?.dispose();
      playlistRef.current = null;
      playerInstanceRef.current = null;
    };
  }, [
    config,
    shouldShowAdBlocker,
    playlist,
    onAdBlockerActive,
    adBlockerBaitScriptUrl,
    events,
    onVideoPlayedEvent,
  ]);

  return (
    <>
      {loading &&
        (CustomLoader ?? (
          <div data-testid="player-loader">
            <Skeleton style={{ aspectRatio: "16 / 9" }} />
          </div>
        ))}
      {/* ToDo: If we change player styling we could extract this to a separate element. For now, it is ok*/}
      {shouldShowAdBlocker && (
        <AdBlockerBanner
          text="Please, disable ad blocker and refresh the page"
          posterUrl={playlist[0].poster}
        />
      )}
      <PlayerDOMElement video-vjs-player="true" ref={placeholderRef} />
    </>
  );
};

// not sure if needed, check styling to-do at the top
VideoPlayer.DOMElement = PlayerDOMElement;

export { VideoPlayer };
