import { createBrowserRouter } from "react-router-dom";
import React from "react";
import { AppRoutes } from "@/shared/constants/Routes";
import { DiscoveryPage } from "@/pages/Discovery/DiscoveryPage";
import { PageLayoutContainer } from "@features/pageLayout/containers/PageLayoutContainer";
import { ClipsPage } from "@/pages/Clips/ClipsPage";
import { ErrorPage } from "@/pages/ErrorPage/ErrorPage";
import { LiveMatchesPage } from "@/pages/LiveMatches/LiveMatchesPage";
import { MatchDetailPage } from "@/pages/LiveMatchDetail/MatchDetailPage";
import { ClipsDetailPage } from "@/pages/ClipsDetail/ClipsDetailPage";
import { CreatorDetailPage } from "@/pages/CreatorDetail/CreatorDetailPage";
import { createMemoryRouter, RouteObject } from "react-router";

interface GetMemoryRouterProps {
  routes?: Array<RouteObject>;
  initialEntries?: Array<string>;
  initialIndex?: number;
}

const appRoutes = [
  {
    element: <PageLayoutContainer />,
    children: [
      {
        path: AppRoutes.Home,
        element: <DiscoveryPage />,
      },
      {
        path: AppRoutes.Highlights,
        element: <ClipsPage />,
      },
      {
        path: AppRoutes.Error,
        element: <ErrorPage />,
      },
      {
        path: AppRoutes.LiveMatches,
        children: [
          {
            index: true,
            element: <LiveMatchesPage />,
          },
          {
            path: AppRoutes.MatchDetail,
            element: <MatchDetailPage />,
          },
        ],
      },
      {
        path: AppRoutes.Clips,
        children: [
          {
            index: true,
            element: <ClipsPage />,
          },
          {
            path: AppRoutes.ClipsDetail,
            element: <ClipsDetailPage />,
          },
        ],
      },
      {
        path: `${AppRoutes.Creators}/${AppRoutes.CreatorDetail}`,
        element: <CreatorDetailPage />,
      },
    ],
  },
];

export const getMemoryRouter = ({
  routes = appRoutes,
  initialEntries = ["/"],
  initialIndex = 0,
}: GetMemoryRouterProps): ReturnType<typeof createMemoryRouter> =>
  createMemoryRouter(routes, { initialEntries, initialIndex });

export const appRouter = createBrowserRouter(appRoutes);
