import styled from "styled-components";
import { WithThemeType } from "@themeTypes/WithThemeType";

interface SingleFilterWrapperProps extends WithThemeType {
  collapsed: boolean;
}

export const SingleFilterWrapper = styled.div<SingleFilterWrapperProps>`
  max-height: ${({ collapsed }) => (collapsed ? "4.5rem" : "25rem")};
  padding: 1.5rem 1.5rem ${({ collapsed }) => (collapsed ? "1.5rem" : "2rem")};
  background-color: ${({ theme }) => theme.colors.navigationBackground};
  position: relative;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
  margin-bottom: 1rem;
`;
