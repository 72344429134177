import styled from "styled-components";

interface SingleFilterContentProps {
  collapsed: boolean;
}

export const SingleFilterContent = styled.div<SingleFilterContentProps>`
  overflow: ${({ collapsed }) => (collapsed ? "hidden" : "scroll")};
  display: grid;
  gap: 0.75rem;
`;
